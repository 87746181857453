import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Petroleum Exploration Society of Great Britain held its 16th annual 'Africa'
conference in  London from August 28 to September 1, as usual in collaboration
with the Geological Society of  Houston. The event kicked off with an evening
lecture at the Geological Society at Burlington  House when Colin Reeves spoke
on 'Gondwana re-assembled: challenges for making the new map  and insights for
exploration'. In it he reported on progress with the new geological map of
Gondwana and the most recent developments in trying to understand the precise
geometry of re assembled Gondwana and its dispersal, starting in the Jurassic.
The latest animations were also  available to delegates on the conference CD. `}</p>
    <p>{`As usual, the main body of the conference provided a variety of excellent papers
on current  hydrocarbon exploration in Africa. There was also an extensive
exhibition of booths from the  industry and its scientific support organisations
and an array of posters outlining current  geoscience research in Africa. Thanks
to support from Renata Schmitt and the IGCP-628 team at  the Federal University
in Rio de Janiero (UFRJ), Colin was able to show the draft version of the new
Gondwana geological map that was first shown at the IGC in Cape Town a year ago
`}{`[picture  below]`}{` among the posters. This raised a good deal of attention and the
expectation of a valuable  new resource of fundamental geo-data once publication
is achieved next year. The conference  attracted more than 400 participants,
despite events in Houston that kept many participants from  the New World at
home. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      